<template>
    <modal ref="modalOrdenarSecciones" titulo="Ordenar sección" no-aceptar adicional="Ordenar" @adicional="ordenar">
        <div class="row mx-0 justify-center">
            <div class="col" />
            <div class="col-10">
                <p class="text-general f-15">Ordena las secciones de la landing</p>
            </div>
            <div class="col" />
        </div>
        <div class="row mx-0 justify-center">
            <div class="col" />
            <div class="col-10">
                <draggable :list="data" group="people" @start="drag=true" @end="drag=false">
                    <div v-for="element in data" :key="element.id" class="mt-2 ml-2 cr-pointer">
                        <i class="icon-drag f-25" />
                        <span class="ml-2">{{ determinarNombre(element.tipo) }}</span>
                    </div>
                </draggable>
            </div>
            <div class="col" />
        </div>
    </modal>
</template>

<script>
import Service from '~/services/landing/landing_sections'

export default {
    data(){
        return {
            drag: false,
            data: []
        }
    },
    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        }
    },
    methods: {
        toggle(secciones){
            this.data = _.cloneDeep(secciones);
            this.$refs.modalOrdenarSecciones.toggle();
        },

        async ordenar(){
            try {
                const model = {
                    secciones: JSON.stringify(_.map(this.data, 'id'))
                };
                const {data} = await Service.ordenarSecciones(model, this.idLanding);
                this.$emit('update');
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$refs.modalOrdenarSecciones.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },

        determinarNombre(tipo){
            switch(parseInt(tipo)){
            case 11:
                return "Imagen Slider";
            case 12:
                return "Imagen Fijo";
            case 21:
                return "Titulo simple";
            case 22:
                return "Titulo línea";
            case 23:
                return "Titulo Fondo";
            case 31:
                return "Categorías sencillo";
            case 32:
                return "Categorías doble";
            case 41:
                return "Productos sencillo";
            case 42:
                return "Productos doble";
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.header-modal{
    height: 53px;
    left: 0px;
}

.btn-anadir-imagen{
    width: 165px;
    height: 28px;
    border: 1px solid #DBDBDB;
    border-radius: 16px;
    background-color: #FFFFFF;
}
.options-box{
    width:35px;
    height:35px;
    bottom:10px;
    left:35%;
    background-color: #00000070;
}
.item-popover:hover{
    cursor: pointer;
    background-color: #F6F9FB;
}
</style>
